<template>
  <div>
      <p>This is the MY-Tickets view.Only MY tickets here.. replica of the tickets view. </p>
  </div>
</template>

<script>
export default {
  created() {
    this.getView()
  },
  methods: {
    getView() {
        this.$http.get('/ViewAccess/mytickets')
        .then(() => {
        })
        .catch(() => {
        })
    },
  }
}
</script>

<style>

</style>
